import { Button, Form, Container, Modal, Row, Col, Stack, Table, } from "react-bootstrap";
import Layout from "../../../layout/Admin/layout";
import { useEffect, useState } from "react";
import{BsEyeFill, BsPencilFill, BsTrashFill} from "react-icons/bs"
import { connect } from "react-redux";
import { addAdminPlans, deleteAdminPlan, getAdminPlanById, getAdminPlans, } from "../../../redux/actions/admin/adminBoxPlanAction";
import UpdatePlanModal from "./update-plan-modal";
import { createPlanValidation } from "../../../validations/adminPage/createPlan-form";
import Swal from "sweetalert2";
import CountryCurrencyConverter from "../CountryCurrencyConverter";

const AdminHome = (props: any) => {
  const countries = [
    {
      "id": 13,
      "iso": "AU",
      "name": "AUSTRALIA",
      "nicename": "Australia",
      "iso3": "AUS",
      "numcode": "36",
      "phonecode": "+61",
      "currencyCode": "AUD",
      "currencyCodeActive": true
    },
    {
      "id": 30,
      "iso": "BR",
      "name": "BRAZIL",
      "nicename": "Brazil",
      "iso3": "BRA",
      "numcode": "76",
      "phonecode": "+55",
      "currencyCode": "BRL",
      "currencyCodeActive": true
    },
    {
      "id": 38,
      "iso": "CA",
      "name": "CANADA",
      "nicename": "Canada",
      "iso3": "CAN",
      "numcode": "124",
      "phonecode": "1",
      "currencyCode": "CAD",
      "currencyCodeActive": true
    },
    {
      "id": 99,
      "iso": "IN",
      "name": "INDIA",
      "nicename": "India",
      "iso3": "IND",
      "numcode": "356",
      "phonecode": "+91",
      "currencyCode": "Rs",
      "currencyCodeActive": true
    },
    {
      "id": 153,
      "iso": "NZ",
      "name": "NEW ZEALAND",
      "nicename": "New Zealand",
      "iso3": "NZL",
      "numcode": "554",
      "phonecode": "+64",
      "currencyCode": "NZD",
      "currencyCodeActive": true
    },
    {
      "id": 156,
      "iso": "NG",
      "name": "NIGERIA",
      "nicename": "Nigeria",
      "iso3": "NGA",
      "numcode": "566",
      "phonecode": "+234",
      "currencyCode": "NGN",
      "currencyCodeActive": true
    },
    {
      "id": 225,
      "iso": "GB",
      "name": "UNITED KINGDOM",
      "nicename": "United Kingdom",
      "iso3": "GBR",
      "numcode": "826",
      "phonecode": "+44",
      "currencyCode": "GBP",
      "currencyCodeActive": true
    },
    {
      "id": 226,
      "iso": "US",
      "name": "UNITED STATES",
      "nicename": "United States",
      "iso3": "USA",
      "numcode": "840",
      "phonecode": "+1",
      "currencyCode": "$",
      "currencyCodeActive": true
    },
    {
      "id": 100,
      "iso": "ZA",
      "name": "SOUTH AFRICA",
      "nicename": "South Africa",
      "iso3": "ZAF",
      "numcode": "710",
      "phonecode": "+27",
      "currencyCode": "R",
      "currencyCodeActive": true
    }
      
    // Add more countries here
  ];
  // const [inputFields, setInputFields] = useState([
  //   { country: '', amount: '', showUsdInput: false, usdAmount: '', discount: '', discountedAmount: '', discountedUsdAmount: '' }
  // ]);
  const [inputFields, setInputFields] = useState([
    { country: '', amount: '', showUsdInput: false, usdAmount: '', discount: '', discountedAmount: '', discountedUsdAmount: '', curr: '' }
  ]);


  const handleFormChange = (index:any, event:any) => {
    const { name, value } = event.target;
    setInputFields(prevInputs => {
      const updatedInputs: any = [...prevInputs];
      updatedInputs[index][name] = value;
      
      // Calculate discounted amount if discount and original amount exist
      if ((name === 'discount' || name === 'amount') && updatedInputs[index].amount && updatedInputs[index].discount) {
        const discount = parseFloat(updatedInputs[index].discount);
        const originalAmount = parseFloat(updatedInputs[index].amount);
        const discountedAmount = originalAmount - (originalAmount * discount / 100);
        updatedInputs[index].discountedAmount = discountedAmount.toFixed(2);
      }
      
      // Calculate discounted usd amount if discount and usd amount exist
      if ((name === 'discount' || name === 'usdAmount') && updatedInputs[index].usdAmount && updatedInputs[index].discount) {
        const discount = parseFloat(updatedInputs[index].discount);
        const originalUsdAmount = parseFloat(updatedInputs[index].usdAmount);
        const discountedUsdAmount = originalUsdAmount - (originalUsdAmount * discount / 100);
        updatedInputs[index].discountedUsdAmount = discountedUsdAmount.toFixed(2);
      }
      const selectedCountry = countries.find(country => country.name === updatedInputs[index].country);
           if (selectedCountry) {
             updatedInputs[index].curr = selectedCountry.currencyCode;
           }
      return updatedInputs;
    });
  }
  // const handleFormChange = (index: number, event: any) => {
  //   const { name, value } = event.target;
  //   console.log(name,value);
  //   setInputFields(prevInputs => {
  //     const updatedInputs: any = [...prevInputs];
  //     updatedInputs[index][name] = value;
      
  //     // Calculate discounted amount if discount and original amount exist
  //     if ((name === 'amount') && updatedInputs[index].amount) {
  //       //const discount = parseFloat(updatedInputs[index].discount);
  //       const originalAmount = parseFloat(updatedInputs[index].amount);
  //       const discountedAmount = originalAmount - (originalAmount * 20 / 100);
  //       updatedInputs[index].discountedAmount = discountedAmount.toFixed(2);
  //     }
      
  //     // Calculate discounted usd amount if discount and usd amount exist
  //     if ((name === 'usdAmount') && updatedInputs[index].usdAmount ) {
  //       //const discount = parseFloat(updatedInputs[index].discount);
  //       const originalUsdAmount = parseFloat(updatedInputs[index].usdAmount);
  //       const discountedUsdAmount = originalUsdAmount - (originalUsdAmount * 20 / 100);
  //       updatedInputs[index].discountedUsdAmount = discountedUsdAmount.toFixed(2);
  //     }
      
  //     // Extract and assign currency code
  //     const selectedCountry = countries.find(country => country.name === updatedInputs[index].country);
  //     if (selectedCountry) {
  //       updatedInputs[index].curr = selectedCountry.currencyCode;
  //     }
  
  //     return updatedInputs;
  //   });
  // }
  
  // const handleCheckboxChange = (index:any) => {
  //   // setInputFields(prevInputs => {
  //   //   const updatedInputs:any = [...prevInputs];
  //   //   updatedInputs[index].showUsdInput = !updatedInputs[index].showUsdInput;
  //   //   return updatedInputs;
  //   // });
  //   setInputFields(prevInputs => {
  //     const updatedInputs = [...prevInputs];
  //     updatedInputs[index].showUsdInput = !updatedInputs[index].showUsdInput;
      
  //     // Reset USD amount and related fields if checkbox is unchecked
  //     if (!updatedInputs[index].showUsdInput) {
  //       updatedInputs[index].usdAmount = '';
  //       updatedInputs[index].discountedUsdAmount = '';
  //     }
      
  //     return updatedInputs;
  //   });
   
  // }

  const handleCheckboxChange = (index: number) => {
    setInputFields(prevInputs => {
      const updatedInputs: any = [...prevInputs];
      updatedInputs[index].showUsdInput = !updatedInputs[index].showUsdInput;
      
      // Reset USD amount and related fields if checkbox is unchecked
      if (!updatedInputs[index].showUsdInput) {
        updatedInputs[index].usdAmount = '';
        updatedInputs[index].discountedUsdAmount = '';
        // Reset currency code when USD input is hidden
        updatedInputs[index].curr = '';
      } else {
        // Extract and assign currency code if checkbox is checked
        const selectedCountry = countries.find(country => country.iso === updatedInputs[index].country);
        if (selectedCountry) {
          updatedInputs[index].curr = selectedCountry.currencyCode;
        }
      }
      
      return updatedInputs;
    });
  }
  
  
  const addFields = () => {
    const newField = { country: '', amount: '', showUsdInput: false, usdAmount: '', discount: '', discountedAmount: '', discountedUsdAmount: '' , curr: ''};
    setInputFields(prevInputs => [...prevInputs, newField]);
    console.log(inputFields);
  }

  const removeFields = (index:any) => {
    setInputFields(prevInputs => {
      const updatedInputs = [...prevInputs];
      updatedInputs.splice(index, 1);
      return updatedInputs;
    });
  }

  const submit = (e:any) => {
    e.preventDefault();
    console.log(inputFields);
  }
  const [adminDetail,       setAdminDetail]     = useState([]);
  // const [planDetailId,   setPlanDetailId]    = useState({});
  const [discountedValue,   setDiscountedValue] = useState("");
  const [pPrice1,           setPPrice1]         = useState(0);
  const [pDiscount1,        setPDiscount1]      = useState(0);
  const [newPlan,           setNewPlan]         = useState<any>({
                                                            pType               : "",
                                                            pCode               : "",
                                                            pPrice              : "",
                                                            pDuration           : "",
                                                            pStorage            : "",
                                                            pDateCreated        : "",
                                                            pPrivateBox         : "",
                                                            pPublicBox          : "",
                                                            pReceivedBox        : "",
                                                            pRecAccessTime      : "",
                                                            pPlanActive         : "",
                                                            privateRec          : 0,
                                                            subsId              : "",
                                                            marketerPlanDiscount: "",
                                                            marketerCommission  : 0,
                                                            discountedPlanPrice : "",
                                                          });
  const [show,              setShow]              = useState(false);
  const [viewModalShow,     setViewModalShow]    = useState(false);
  const [viewModalOpen,     setViewModalOpen]    = useState(false);
  const [updatePlanShow,    setUpdatePlanShow]   = useState(false);
  const [updatePlanOpen,    setUpdatePlanOpen]   = useState(false);
  const [viewModalDetails,  setViewModalDetails] = useState<any>({
                                                                  planType              : "",
                                                                  planCode              : "",
                                                                  planPrice             : "",
                                                                  planDuration          : "",
                                                                  planStorage           : "",
                                                                  created               : "",
                                                                  privateBoxes          : "",
                                                                  publicBoxes           : "",
                                                                  recievedBoxes         : "",
                                                                  recievedBoxAccessTime : "",
                                                                  planisActive          : "",
                                                                  discountedPlanPrice   : "",
                                                                  marketerPlanDiscount  : "",
                                                                  marketerCommission    : "",
                                                                });
  let [isValid,             setIsValid] = useState(true);
  let [errors,              setErrors]  = useState<any>({
                                                          pType          : "",
                                                          pCode          : "",
                                                          pPrice         : "",
                                                          pDuration      : "",
                                                          pStorage       : "",
                                                          pDateCreated   : "",
                                                          pPrivateBox    : "",
                                                          pPublicBox     : "",
                                                          pReceivedBox   : "",
                                                          pRecAccessTime : "",
                                                          pPlanActive    : "",
                                                          privateRec     : "",
                                                          subsId         : "",
                                                        });
  let [checkValidation,     setCheckValidation] = useState<any>({
                                                                  pType          : false,
                                                                  pCode          : false,
                                                                  pPrice         : false,
                                                                  pDuration      : false,
                                                                  pStorage       : false,
                                                                  pDateCreated   : false,
                                                                  pPrivateBox    : false,
                                                                  pPublicBox     : false,
                                                                  pReceivedBox   : false,
                                                                  pRecAccessTime : false,
                                                                  pPlanActive    : false,
                                                                  privateRec     : false,
                                                                  subsId         : false,
                                                                });
  const handleClose = () => {
    setShow(false);
    setDiscountedValue("");
    setInputFields([
      { country: '', amount: '', showUsdInput: false, usdAmount: '', discount: '', discountedAmount: '', discountedUsdAmount: '', curr: '' }
    ])
  }

  const handleShow = () =>{ setShow(true);
    setInputFields([
      { country: '', amount: '', showUsdInput: false, usdAmount: '', discount: '', discountedAmount: '', discountedUsdAmount: '', curr: '' }
    ]);
  }

  const handleUpdatePlanClose = () => {
    setUpdatePlanShow(false);
    setUpdatePlanOpen(false);
  };

  const [boxPlanIdProp, setboxPlanIdProp] = useState("");
  const handleUpdatePlanShow = (e: any) => {
    let boxPlanId = e ;
    if (boxPlanId) {
      props.getAdminPlanById({ id: boxPlanId });
      setboxPlanIdProp(boxPlanId);
      setUpdatePlanOpen(true);
    }
  };

  const handleViewModalClose = () => {
    setViewModalShow(false);
    setViewModalOpen(false);
  };

  const handleViewModalShow = (e: any) => {
    let boxPlanId = e;
    if (boxPlanId > 0) {
      props.getAdminPlanById({ id: boxPlanId });
      setViewModalOpen(true);
    }
  };

  useEffect(() => {
    props.getAdminPlans();
  }, []);

  useEffect(() => {
    if (props.adminBoxPlanData) {
      setAdminDetail(props.adminBoxPlanData);
      
    }
  }, [props.adminBoxPlanData]);
  
  let result: any;
  useEffect(() => {
    if (props.adminBoxPlanDataById && viewModalOpen) {
      result                                 = props.adminBoxPlanDataById   ? props.adminBoxPlanDataById   : "";
      console.log(result)
      viewModalDetails.planType              = result.planType              ? result.planType              : "";
      viewModalDetails.planCode              = result.planCode              ? result.planCode              : "";
      viewModalDetails.planPrice             = result.planPrice             ? result.planPrice             : "";
      viewModalDetails.planDuration          = result.planDuration          ? result.planDuration          : "";
      viewModalDetails.planStorage           = result.planStorage           ? result.planStorage           : "";
      viewModalDetails.created               = result.created               ? new Date(result.created).toLocaleDateString() : "";
      viewModalDetails.privateBoxes          = result.privateBoxes          ? result.privateBoxes          : "";
      viewModalDetails.publicBoxes           = result.publicBoxes           ? result.publicBoxes           : "";
      viewModalDetails.recievedBoxes         = result.recievedBoxes         ? result.recievedBoxes         : "";
      viewModalDetails.recievedBoxAccessTime = result.recievedBoxAccessTime ? result.recievedBoxAccessTime : "";
      viewModalDetails.planisActive          = result.planisActive          ? result.planisActive          : "";
      viewModalDetails.marketerPlanDiscount  = result.marketerPlanDiscount  ? result.marketerPlanDiscount  : "";
      viewModalDetails.marketerCommission    = result.marketerCommission    ? result.marketerCommission    : "";
      viewModalDetails.discountedPlanPrice   = result.discountedPlanPrice   ? result.discountedPlanPrice   : "";
      viewModalDetails.inputField=result.currencyCode?JSON.parse(result.currencyCode):[]
      setViewModalShow(true);
    } else if (props.adminBoxPlanDataById && updatePlanOpen) {
      setUpdatePlanShow(true);
    }
  }, [props.adminBoxPlanDataById]);

  // var pPrice2=0;
  var pDiscount2=0;
  const handleChange = (event: any) => {
    if(event.target.name == 'pPrice' ){
      setPPrice1(event.target.value)
    }
  
    if(event.target.name == 'marketerPlanDiscount'){
      setPDiscount1(event.target.value)
      pDiscount2= event.target.value;
    }
  
    if(pPrice1 && pDiscount2 ){
      let disPrice = pPrice1 - ((pPrice1 * pDiscount2)/100);
      let finalValue = '' + disPrice
      setDiscountedValue(finalValue);
    }
    setCheckValidation({ ...checkValidation, [event.target.name]: true, });
    setNewPlan({ ...newPlan,[event.target.name]: event.target.value, });
  };

  useEffect(() => {
    const validationErrors = createPlanValidation(newPlan);
    isValid = Object.keys(validationErrors).length === 0;
    setIsValid(isValid);
    setErrors(validationErrors);
  }, [newPlan]);

  const handleSubmit = (e: any) => {
    const currentDate = new Date().toISOString();
    e.preventDefault();
    let payload = {
      planType              : "",
      planCode              : "",
      planPrice             : "",
      privateBoxes          : "",
      publicBoxes           : "",
      recievedBoxes         : "",
      planDuration          : "",
      planStorage           : "",
      recievedBoxAccessTime : "",
      plan_id               : "",
      privateRecipients     : 0,
      marketerPlanDiscount  : "",
      marketerCommission    :0,
      discountedPlanPrice   : "",
      currencyCode          : "",
      created:"",
      modified:""
    };
    
    payload.planType              = newPlan.pType;
    payload.planCode              = newPlan.pCode;
    payload.planPrice             = newPlan.pPrice;
    payload.privateBoxes          = newPlan.pPrivateBox;
    payload.publicBoxes           = newPlan.pPublicBox;
    payload.recievedBoxes         = newPlan.pReceivedBox;
    /* payload.totalBoxes         = newPlan.pPrivateBox+newPlan.pPublicBox; */
    payload.planDuration          = newPlan.pDuration;
    payload.planStorage           = newPlan.pStorage;
    payload.recievedBoxAccessTime = newPlan.pRecAccessTime;
    payload.privateRecipients     = newPlan.privateRec;
    payload.plan_id               = newPlan.subsId;
    payload.marketerPlanDiscount  = "0";
    payload.marketerCommission    = parseInt(newPlan.marketerCommission);
    payload.discountedPlanPrice   = discountedValue;
    payload.currencyCode=JSON.stringify(inputFields);
    payload.created=currentDate;
    payload.modified=currentDate;
    console.log(inputFields)  
    /*payload.plan_id= newPlan.pP, */
    if (isValid) {
      props.addAdminPlans(payload)
    
    }
  };

  const removePlan = (e: any,name:any) => {
    let boxPlanId = e;
    let boxPlanName = name;
    if (boxPlanId > 0) {
      Swal.fire({
        title              : `Do you want to remove ${boxPlanName} plan? `,
        showDenyButton     : true,
        showCancelButton   : false,
        confirmButtonText  : "YES",
        denyButtonText     : `NO`,
        confirmButtonColor : "green",
        showCloseButton    : true,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Plan removed !!", "", "success");
          props.deleteAdminPlan({ id: boxPlanId });
        }
      });
      //
    }
  };
 useEffect(() => {
if(props.deleteAdminBoxPlanData)
{
  props.getAdminPlans();
}
 }, [props.deleteAdminBoxPlanData]);

  useEffect(() => {
    if(props.updateAdminBoxPlanData)
    {
      props.getAdminPlans();
      handleUpdatePlanClose();
    }
  }, [props.updateAdminBoxPlanData]);
   
    useEffect(() => {
      if(props.addAdminBoxPlanData)
      {
        props.getAdminPlans();
        handleClose()
      }
    }, [props.addAdminBoxPlanData]);

  const modalDetail = [
    {
      field1: "Plan Name",
      field2: "pType",
    },
    {
      field1: "Plan Code",
      field2: "pCode",
    },
    
    {
      field1: "Plan Duration",
      field2: "pDuration",
    },
    {
      field1: "Plan Storage",
      field2: "pStorage",
    },

    /* {
      field1: "Plan Created",
      field2: "pDateCreated",
    }, */

    {
      field1: "Private Boxes",
      field2: "pPrivateBox",
    },

    {
      field1: "Public Boxes",
      field2: "pPublicBox",
    },
    {
      field1: "Received Boxes",
      field2: "pReceivedBox",
    },
    {
      field1: "Private Box Recipients",
      field2: "pRecepientPrivate",
    },
    {
      field1: "Received Boxes Duration",
      field2: "pRecAccessTime",
    },
    {
      field1: "Marketer Plan Discount",
      field2: "marketerPlanDiscount",
    },
    // {
    //   field1: "Price After Discount (R)",
    //   field2: "pAfterDiscount",
    // },
    {
      field1: "Marketer Commission",
      field2: "marketerCommission",
    },
    {
      field1: "Plan Price (R)",
      field2: "pPrice",
    },
    // {
    //   field1: "Paypal Plan Subscription Id",
    //   field2: "subsId",
    // },

    /*  {
      field1: "Plan Active",
      field2: "pPlanActive",
    }, */
  ];

//---------------------------------------------------------------------------------
  
  return (
    <>
      <UpdatePlanModal show={updatePlanShow} onHide={handleUpdatePlanClose} boxPlanIdProp={boxPlanIdProp} />
      <Layout>
        <Row>
          <Col md={8}>
            <h3 className="text-warning">Box Plans</h3>
          </Col>
          <Col md={4}>
            <Stack direction="horizontal" gap={3}>
              <Button variant="info" className="ms-auto" onClick={handleShow}>Add</Button>
            </Stack>
          </Col>
        </Row>
        <hr style={{border:'1px solid #E79E1F'}}/> 
        <div className="users scrollBar">
          <Table responsive>
            <thead>
              <tr>
                <th className="text-warning text-center">S.No</th>
                <th className="text-warning text-center">Plan Name</th>
                <th className="text-warning text-center">Plan Code</th>
                <th className="text-warning text-center">Plan Price (R)</th>
                <th className="text-warning text-center">Plan Duration</th>
                <th className="text-warning text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {adminDetail && adminDetail.map((list: any, index: number) => {
                return (
                  <>
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{list.planType}</td>
                      <td className="text-center">{list.planCode}</td>
    
                    {list.currencyCode==='null' ||list.currencyCode==='' ?<td className="text-center">{list.planPrice}</td>:
                  <td>
      
                       
                        <table  >
                        {/* <thead>
                          <tr>
                            <th className="text-center">Country</th>
                            <th className="text-center">Plan Price</th>
                            <th className="text-center">USD Price</th>
                          </tr>
                        </thead> */}
                            <tbody  >
                              {JSON.parse(list.currencyCode) &&JSON.parse(list.currencyCode).map((input: any, index: any) => (
                                <tr key={index}>
                                  {/* <td>{input.country}</td> */}
                                  <td className="block-cell">
                                    {input.amount} <span className="currency">{input.curr}</span>
                                  </td>
                                  {input.showUsdInput && (
                                    <td className="block-cell">
                                      {input.usdAmount} <span className="currency">$</span>
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                      </table>
                   
                        
                      
                      </td>
                }
                      <td className="text-center">{list.planDuration}</td>
                      <td className="text-center">
                        <button type="submit" className="btn btn-outline-success" value={list.id} onClick={() =>handleViewModalShow(list.id)} ><BsEyeFill/></button>
                        {/* <button type="submit" className="btn btn-outline-warning m-2" value={list.id} onClick={()=>handleUpdatePlanShow(list.id)} ><BsPencilFill/></button> */}
                        <button type="submit" className="btn btn-outline-danger" value={list.id} id={list.planType} onClick={()=>removePlan(list.id,list.planType)} ><BsTrashFill/></button>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* -------------ADD PLAN MODAL---------------  */}
        <Modal show={show} size="lg" backdrop="static" onHide={handleClose} scrollable={true} className="adminModal">
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#E79E1F" }}>Add Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Container>
                <Row>
                  {modalDetail && modalDetail.map((data: any, index) => {
                    return (
                      <>
                        <Col md={ Object.keys(modalDetail).length - 1 == index ? 12 : 6 } >
                          {  data.field1 == 'Plan Price (R)' ? <Form.Group className="mb-3 mt-3 " >
                              <Form.Label style={{ color: "whitesmoke" }}> {data.field1} </Form.Label>       
           {inputFields.map((input, index) => (
        <div key={index}>
          <div className="row">
          <Form.Group className="mb-3 mt-3">
          <Form.Label style={{ color: "whitesmoke" }}>
              Country
            </Form.Label>
            <Form.Select
              name='country'
              value={input.country}
              onChange={(event) => handleFormChange(index, event)}
            >
              <option value="">Select Country</option>
              {countries.map(country => (
                <option key={country.id} value={country.name}>{country.name} - {country.currencyCode}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
          <Form.Label style={{ color: "whitesmoke" }}>
             Discount
            </Form.Label>
            <Form.Control
              name='discount'
              placeholder='Amount'
              value={input.discount}
              onChange={(event) => handleFormChange(index, event)}
            />
          </Form.Group> 
            <div className="col-lg-6">
          
          <Form.Group className="mb-3">
          <Form.Label style={{ color: "whitesmoke" }}>
             Actual Price
            </Form.Label>
            <Form.Control
              name='amount'
              placeholder='Amount'
              value={input.amount}
              onChange={(event) => handleFormChange(index, event)}
            />
          </Form.Group> 
          {input.showUsdInput && (
            <Form.Group className="mt-3 mb-3 ">
               <Form.Label  style={{ color: "whitesmoke" }}>
              USD Amount
            </Form.Label>
              <Form.Control
                name='usdAmount'
                placeholder='Enter USD Amount'
                value={input.usdAmount}
                onChange={(event) => handleFormChange(index, event)}
              />
            </Form.Group>
          )}
            </div>
            <div className="col-lg-6">
        
            <Form.Group className="mb-3 " style={{ color: "whitesmoke" }}>
            <Form.Label>
              Price After Discount
            </Form.Label>
            <Form.Control
              type="text"
              value={input.discountedAmount}
              disabled
            />
            {input.showUsdInput && input.usdAmount && (
              <div>
                <Form.Label  className="mt-3">
                  Discounted USD Amount
                </Form.Label>
                <Form.Control
                  type="text"
                  value={input.discountedUsdAmount}
                  disabled
                />
              </div>
            )}
          </Form.Group>
            </div>
          </div>
        
          <Form.Group className="mb-3 " style={{ color: "whitesmoke" }}>
            <Form.Check
              type="checkbox"
              label="Show USD amount"
              checked={input.showUsdInput}
              onChange={() => handleCheckboxChange(index)}
            />
          </Form.Group>
        
        
          
                                  {/* <Button variant="danger" type='button' onClick={() => removeFields(index)} disabled={inputFields.length<=1}>Remove</Button>{' '}
            <Button variant="primary" type='button' onClick={addFields}   disabled={inputFields.some(input => !input.country || !input.amount)}>Add More..</Button> */}
                                  {index === inputFields.length - 1 && ( // Render only for the last input field
                                    <>
                                      <Button variant="danger" disabled={inputFields.length<=1} type='button' onClick={() => removeFields(index)}>Remove</Button>{' '}
                                      <Button variant="primary"  disabled={inputFields.some(input => !input.country || !input.amount)} type='button' onClick={addFields}  className="my-0">Add More..</Button>
                                    </>
                                  )}
                                </div>
                              ))}

                            </Form.Group> :
                            <Form.Group className="mb-3 mt-3 " >
                              <Form.Label style={{ color: "whitesmoke" }}>
                                {data.field1 == 'Marketer Plan Discount' ? `${data.field1} (%)` : data.field1 }
                              </Form.Label>
                              <Form.Control type="text" name={data.field2} onInput={( e: React.ChangeEvent<HTMLInputElement> ) => { e.target.value = e.target.value.trimStart();}} onChange={handleChange} autoFocus
                               defaultValue={data.field1 === 'Marketer Plan Discount' ? "20" : ""}
                               disabled={data.field1 === 'Marketer Plan Discount'}
                              />
                              {checkValidation[data.field2] && errors[data.field2] && 
                                ( <p className="text-danger"> {errors[data.field2]} </p>)
                              }
                            </Form.Group>
                          }
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto" style={!isValid ? {cursor:"not-allowed"} : {cursor:"pointer"}}>
                  <Button className="backBtn" variant="primary" type="submit" disabled={!isValid ||inputFields.some(input => !input.country || !input.amount)}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          {/*  <Modal.Footer></Modal.Footer> */}
        </Modal>

        <Modal show={viewModalShow} size="lg" backdrop="static" onHide={handleViewModalClose} className="adminModal">
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#E79E1F" }}>
              Plan Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                <Row>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Plan Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.planType}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Plan Code
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.planCode}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Plan Price (R)
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.planPrice}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Plan Duration
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.planDuration}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Plan Storage
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.planStorage}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Plan Creation Date
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.created}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Public Box
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.publicBoxes}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>

                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Private Boxes
                      </Form.Label>
                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.privateBoxes}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Recieved Box
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.recievedBoxes}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Rec. Box Access Time
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.recievedBoxAccessTime}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  {/* new add marketers */}
                  {/* <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Marketer Plan Discount (%)
                      </Form.Label> */}

                      {/* <Form.Control
                        type="text"
                       
                        value={viewModalDetails.marketerPlanDiscount}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col> */}
                  {/* <Col md={6}>
                  <Form.Group
                    className="mb-3 mt-3 "
                    //controlId={data.field2}
                  >
                    <Form.Label style={{ color: "whitesmoke" }}>
                      Price After Discount (R)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      // name="discountedValue"
                      value={viewModalDetails.discountedPlanPrice}
                      // onChange={handleChange}
                      disabled
                      autoFocus
                    />
                  </Form.Group>
                </Col> */}
                  <Col className="col-md-auto" md={6 }>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Marketer Commission
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={viewModalDetails.marketerCommission}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-md-auto" md={6 }>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                      Marketer Plan Discount (%)
                      </Form.Label>

                      <Form.Control
                        type="number"
                        // name={data.field1}
                        /*  onChange={onChangeHandler} */
                        value={20}
                        autoFocus
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  {/* new add marketers */}
                  {/*  <Col className="col-md-auto" md={6}>
                    <Form.Group className="mb-3 mt-3 ">
                      <Form.Label style={{ color: "whitesmoke" }}>
                        Plan Active
                      </Form.Label>

                      <Form.Control
                        type="text"
                        // name={data.field1}
                          onChange={onChangeHandler} 
                        value={viewModalDetails.planisActive}
                        autoFocus
                        disabled
                        
                      />
                    </Form.Group>
                  </Col> */}
                    <Col md={12}>
               <Form.Group className="mb-3 mt-3 " >
                       {viewModalDetails.inputField&& viewModalDetails.inputField.length!=0 && <Form.Label style={{ color: "whitesmoke" }}> Plan Price </Form.Label>}
                        
        {viewModalDetails.inputField &&viewModalDetails.inputField.map((input:any, index:any) => (
          <div key={index}>
          
              <div className="row">
              <Form.Group className="mb-3">
                <Form.Label style={{ color: "whitesmoke" }}>
              Country
            </Form.Label>
              <Form.Control
                name='country'
                placeholder='Country'
                disabled
                value={input.country}
                onChange={(event) => handleFormChange(index, event)}
              />
            </Form.Group>
                <div className="col-lg-6">
         
            <Form.Group className="mb-3">
            <Form.Label style={{ color: "whitesmoke" }}>
              Actual price
            </Form.Label>
              <Form.Control
                name='amount'
                placeholder='Amount'
                value={input.amount}
                disabled
                onChange={(event) => handleFormChange(index, event)}
              />
            </Form.Group>
            {input.showUsdInput && (
              <Form.Group className="mb-3">
                 <Form.Label style={{ color: "whitesmoke" }}>
              USD Amount
            </Form.Label>
                <Form.Control
                  name='usdAmount'
                  placeholder='Enter USD Amount'
                  value={input.usdAmount}
                  disabled
                  onChange={(event) => handleFormChange(index, event)}
                />
              </Form.Group>
            )}
                </div>
                <div className="col-lg-6">
                <Form.Group className="mb-3 " style={{ color: "whitesmoke" }}>
              <Form.Label>
              Price After Discount 
              </Form.Label>
              <Form.Control
                type="text"
                value={input.discountedAmount}
                disabled
              />
              {input.showUsdInput && input.usdAmount && (
                <div>
                  <Form.Label  className="mt-3">
                    Discounted USD Amount
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={input.discountedUsdAmount}
                    disabled
                  />
                </div>
              )}
            </Form.Group>
                </div>
              </div>
       
            <Form.Group className="mb-3" style={{ color: "whitesmoke" }}>
              <Form.Check
                type="checkbox"
                label="Show USD amount"
                checked={input.showUsdInput}
                disabled
                onChange={() => handleCheckboxChange(index)}
              />
            </Form.Group>
         
            {/* <Form.Group className="mb-3" style={{ color: "whitesmoke" }}>
            <Form.Label >
            Marketer Plan Discount (%)
              </Form.Label>
              <Form.Control
                name='discount'
                placeholder='Discount (%)'
                value={input.discount}
                onChange={(event) => handleFormChange(index, event)}
              />
            </Form.Group> */}
        
          </div>
        ))}
    
                        </Form.Group>
               </Col>
                </Row>
              </Container>
              <Row className="justify-content-md-center mt-10">
                <Col className="" md="auto">
                  {/*   <Button
                    variant="primary"
                    type="submit"
                    // onClick={handleSubmit}
                  >
                    Submit
                  </Button> */}
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          {/*  <Modal.Footer></Modal.Footer> */}
        </Modal>
      </Layout>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getAdminPlans    : (data: any) => dispatch(getAdminPlans(data)),
  getAdminPlanById : (data: any) => dispatch(getAdminPlanById(data)),
  addAdminPlans    : (data: any) => dispatch(addAdminPlans(data)),
  deleteAdminPlan  : (data: any) => dispatch(deleteAdminPlan(data)),
});

const mapStateToProps = (state: any) => {
  return {
    adminBoxPlanData       : state.AdminBoxPlanReducer && state.AdminBoxPlanReducer.adminBoxPlanData ? state.AdminBoxPlanReducer.adminBoxPlanData : null,
    adminBoxPlanDataById   : state.AdminBoxPlanReducer && state.AdminBoxPlanReducer.adminBoxPlanDataById ? state.AdminBoxPlanReducer.adminBoxPlanDataById : null,
    addAdminBoxPlanData    : state.AdminBoxPlanReducer && state.AdminBoxPlanReducer.addAdminBoxPlanData ? state.AdminBoxPlanReducer.addAdminBoxPlanData : null,
    deleteAdminBoxPlanData : state.AdminBoxPlanReducer && state.AdminBoxPlanReducer.deleteAdminBoxPlanData ? state.AdminBoxPlanReducer.deleteAdminBoxPlanData : null,  
    updateAdminBoxPlanData :  state.AdminBoxPlanReducer && state.AdminBoxPlanReducer.updateAdminBoxPlanData ? state.AdminBoxPlanReducer.updateAdminBoxPlanData : null,       
    state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);
