import { useState,  useEffect}             from "react";
import { connect }                         from "react-redux";
import { Button, Form, Modal }                    from 'react-bootstrap';
import logo                                from '../../assets/images/BB.png';
import { verifyUserByOTP,authResend }      from '../../redux/actions/authAction';
import Footer                              from "../../layout/elements/footer";
import { Link,useLocation,useNavigate }    from "react-router-dom";
import { Email_n_MobileOTPFormValidation } from '../../validations/Email_n_MobileOTPFormValidation';
import { clearCommoneMessage }             from '../../redux/actions/boxAction';
import { COMMON_MESSAGE }                  from "../../redux/constant";
import { getProfile }                      from "../../redux/actions/profileAction";
import Swal from "sweetalert2";
import img5 from '../../assets/images/bbapp2.png';
import appstore from '../../assets/images/appstore.png';
import googleplay from '../../assets/images/googleplay.png';
const EmailAndMobileOTPVerification = (props:any) => {
  
  const redirectTo                               = useNavigate();
  const [formData,            setFormData]       = useState({emailOTP:'',mobileOTP:''});
  const [errors,              setErrors]         = useState({emailOTPErr:'',mobileOTPErr:'',responseError:''});
  const [successMessage,      setSuccessMessage] = useState({responseSuccess:''});
  const [loading,             setLoading]        = useState(true);
  const { state }                                = useLocation();
  const [resendOTP_Flag,      setResendOTP_Flag] = useState(false);
  const userAgent = navigator.userAgent ;
  const isIOS = /iPad|iPhone|iPod/.test(userAgent);
  const isAndroid = /android/i.test(userAgent);
  const [show, setShow] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');

  const handleClose = () => setShow(false);
  useEffect(()=>{
    const alertShown = localStorage.getItem('alertShown');
    if(!props?.profileData?.verified &&alertShown==='true' ){
      setShow(true);
      localStorage.setItem('alertShown', 'true');
    }
  },[props.profileData])
  useEffect(()=>{
    window.scrollTo({ top: 0,behavior: "smooth" });
    if(performance.navigation.type === 1){
      setTimeout(() =>{
        setLoading(false);
      },1000)
    }
  },[])
  
  const handleChange = (event: any) => {
    setFormData({...formData, [event.target.name]:event.target.value});
    const checkValidation = Email_n_MobileOTPFormValidation({...formData, [event.target.name]:event.target.value});
    setErrors(checkValidation);
  };
            
  const handleSubmit = (e:any) => {
    e.preventDefault();
    const checkValidation =Email_n_MobileOTPFormValidation(formData);
    setErrors(checkValidation);
    let isValid = Object.keys(checkValidation).length === 0;
    if(isValid){
      if(state || localStorage.getItem('id')){
        let payLoad = {
          userId   : state ? state.id : localStorage.getItem('id'),
          smsOTP   : formData.mobileOTP,
          emailOTP : formData.emailOTP
        }
        setLoading(true);
        props.verifyUserByOTP(payLoad);
        }else{
            redirectTo('/login');
        }
    }
  }
 
  const resendOTP = () =>{
    if(localStorage.getItem('email') && localStorage.getItem('email') !== ''){
      setLoading(true);
      let resendEmail = localStorage.getItem('email');
      props.resend({"email":resendEmail});
    }else{
      redirectTo('/login');
    }
  }

  useEffect(() =>{
    if(props.message){
      if(props.message.status === 'error'){
        setTimeout(() =>{
          setLoading(false);
          setSuccessMessage({responseSuccess:''})
          setErrors({...errors,responseError:((props.message && props.message.message) ? props.message.message : '')});
          props.clearMessageData();
        },1000)
      }else if(props.message.status === 'success' && props.message.isActive === 0){
        setTimeout(()=>{
          setLoading(false);
          setFormData({emailOTP:'',mobileOTP:''});
          setSuccessMessage({responseSuccess:(props.message && props.message.message ? props.message.message : '')})
          props.clearMessageData();
        },1000)
        
        setTimeout(() =>{
          setResendOTP_Flag(true);
        },15*60*1000)
      }else if(props.message.Token){
        setTimeout(() =>{
          setLoading(false);
          setErrors({...errors,responseError:''});
          setFormData({emailOTP:'',mobileOTP:''});
          setSuccessMessage({responseSuccess:'OTP Verified, Signing-In... please wait!'});
          props.getProfile();
        },1000)
      }
    }
  },[props.message])

  useEffect(() => {
    if (props.profileData) {
      console.log(props.profileData)
      localStorage.setItem('profileData', JSON.stringify(props.profileData));
      if(props.profileData.roles && props.profileData.roles[0] === 'Admin'){
        setTimeout(() =>{
            redirectTo('/admin')    
        },800)
      }else if (props.profileData.verified) {
        setTimeout(() =>{
            redirectTo('/homepage');    
        },800)
      } else {
        setTimeout(() =>{
          localStorage.setItem('alertShown', 'true');
          // if (isIOS) {
          //   setDownloadLink('https://apps.apple.com/us/app/beyondbox-messenger/id6448447137');
          // } else if (isAndroid) {
          //   setDownloadLink('https://play.google.com/store/apps/details?id=com.beyondboxapp');
          // } else {
          //   setDownloadLink('');
          // }
          setShow(true)

          //  redirectTo('/edit-profile');    
        },800)
      }
    }
  }, [props.profileData])
 
//---------------------------------------------------------------//

return (
    <>
  {/* <Modal show={show} onHide={handleClose} centered  backdrop="static" keyboard={false}  className='modalDownloadApp'>
        <Modal.Body>
          <p className='mb-1'><span><strong style={{color: '#f7a817'}}>Congratulations!</strong></span> Your account has been activated</p>
          <p className='mb-5'><strong>Welcome to the <span style={{color: '#f7a817'}}>Beyond</span>Box Family!</strong></p>
          <p className='mb-1'>Please <a style={{color: '#f7a817', textDecoration: 'underline'}} href="https://play.google.com/store/apps/details?id=com.beyondboxapp">Download</a> the App and log in to your account to</p>
          <p>contine setting up your profile...</p>
          <div className='text-center'><img className="app-img" src={img5} alt="" /></div>
        </Modal.Body>
      </Modal> */}
     <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} className='modalDownloadApp'>
        <Modal.Body>
          <p className='mb-1'>
            <span><strong style={{ color: '#f7a817' }}>Congratulations!</strong></span> Your account has been activated.
          </p>
          <p className='mb-5'>
            <strong>Welcome to the <span style={{ color: '#f7a817' }}>Beyond</span>Box Family!</strong>
          </p>

          {isIOS ? (
            <>
              <p className='mb-1'>
                Please <a style={{ color: '#f7a817', textDecoration: 'underline' }} href="https://apps.apple.com/us/app/beyondbox-messenger/id6448447137" target="_blank" rel="noopener noreferrer">Download</a> the App and log in to your account to continue setting up your profile...
              </p>
            </>
          ) : isAndroid ? (
            <>
              <p className='mb-1'>
                Please <a style={{ color: '#f7a817', textDecoration: 'underline' }} href="https://play.google.com/store/apps/details?id=com.beyondboxapp" target="_blank" rel="noopener noreferrer">Download</a> the App and log in to your account to continue setting up your profile...
              </p>
            </>
          ) : (
            <>
              <p className='mb-4'>Download the app to continue setting up your profile:</p>
              <div className="btnBlock text-center d-flex justify-content-center">
                <a href="https://apps.apple.com/us/app/beyondbox-messenger/id6448447137" className="appstore pointer" >
                  <img src={googleplay} alt="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.beyondboxapp" className="googleplay">
                  <img src={appstore} alt="" />
                </a>
              </div>
            </>
          )}

          <div className='text-center mt-4'>
            <img className="app-img" src={img5} alt="Download App" />
          </div>
        </Modal.Body>
      </Modal>

    <div className="createAccount">
    <div className="userDetailBlock">
        <div className="formWrap align-items-center d-md-flex flex-column h-100 justify-content-center">
            <div className="text-center">
            <Link to="/">
              <img src={logo} className="App-logo" alt="logo" />
              </Link></div>
            <h2 className="text-center">User Verification</h2>
            {loading ? <p className="lds-dual-ring d-block" ></p>
            :
              <>
                {(errors && !successMessage.responseSuccess && errors.responseError )&& <p className={errors && errors.responseError ? `text-danger` : `text-success`}>{errors && errors.responseError ? errors.responseError : ''}</p>}
                {successMessage && successMessage.responseSuccess && <p className="text-success">{successMessage.responseSuccess}</p>}
              </>
            }  
            <Form className="w-100">
                <Form.Group className="formRow">
                    <Form.Label>Email OTP</Form.Label>
                    <Form.Control type="text" name="emailOTP" id="emailOTP" maxLength={4} pattern="^[0-9]{4}$" placeholder="Enter valid email otp"  onChange={handleChange} value={formData && formData.emailOTP ? formData.emailOTP : ''}/>
                    {errors && errors.emailOTPErr && <p className="text-danger">{errors && errors.emailOTPErr ? errors.emailOTPErr : '' }</p>}
                </Form.Group>
                <Form.Group className="formRow">
                    <Form.Label>Mobile OTP</Form.Label>
                    <Form.Control type="text" name="mobileOTP" id="mobileOTP" maxLength={4} pattern="^[0-9]{4}$" placeholder="Enter valid mobile otp"  onChange={handleChange} value={formData && formData.mobileOTP ? formData.mobileOTP : ''}/>
                    {/* {resendOTP_Flag &&  <Link to={'#'} className="float-end text-light" onClick={resendOTP}>Resend OTP</Link>} */}
                    <Link to={'#'} className="float-end text-light" onClick={resendOTP}>Resend OTP</Link>
                    {errors && errors.mobileOTPErr && <p className="text-danger">{errors && errors.mobileOTPErr ? errors.mobileOTPErr : '' }</p>}
                </Form.Group>
                <div className="d-flex  justify-content-between" style={!loading && (Object.keys(errors).length === 0)  ? {} :{cursor:'not-allowed'} }>
                  <Button onClick={()=>{redirectTo('/login')}} className="font-regular button-secondary"  variant="primary" type="button">CANCEL</Button>
                  <Button onClick={handleSubmit} className="font-regular button-secondary" variant="primary" type="submit" disabled={!loading && ( Object.keys(errors).length === 0)  ? false : true}>CONTINUE</Button>
                </div>
            </Form>
        </div>
    </div>
</div>
<Footer/>
</>
  );
  }
  const mapDispatchToProps = (dispatch: any) => ({
    clearMessageData  : (data: any) => dispatch({type:COMMON_MESSAGE, data:null}),
    resend            : (data: any) => dispatch(authResend(data)),
    getProfile        : (data: any) => dispatch(getProfile(data)),
    verifyUserByOTP   : (data: any) => dispatch(verifyUserByOTP(data)),
    clearMessageProps : (data: any) => dispatch(clearCommoneMessage(data)),
});

const mapStateToProps = (state: any) => {
    return {
      profileData : (state.ProfilePageReducer && state.ProfilePageReducer.profileData) ? state.ProfilePageReducer.profileData : null,
      message     : (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EmailAndMobileOTPVerification);